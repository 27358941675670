







































import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { MessageInfo } from '@/types/message'

@Component
export default class Add extends Vue {
  private info: MessageInfo = {
    isMessage: '1',
    messageTitle: '',
    messageContent: '',
    messageReceiverList: []
  }

  private rules= {
    messageTitle: [
      { required: true, message: '请输入消息标题', trigger: 'change' }
    ],
    messageContent: [
      { required: true, message: '请输入消息内容', trigger: 'change' }
    ],
    messageReceiverList: [
      { required: true, message: '请选择接收人', trigger: 'blur' }
    ]
  }

  private userList= []
  private checkboxGroup: string [] = []
  private submitShow = false
  private all = false

  created () {
    this.getUserList()
    this.$route.params.id && this.getData()
  }

  getData () {
    this.$axios.get(this.$apis.message.selectMessageByMessageId, {
      messageId: this.$route.params.id
    }).then((res) => {
      this.info = res
      if (res.messageReceiverList && res.messageReceiverList.length > 0) {
        res.messageReceiverList.map((item: {receiverId: string}) => {
          this.checkboxGroup.push(item.receiverId)
        })
      }
    })
  }

  getUserList () {
    this.$axios.get(this.$apis.developer.selectApiUserList).then((res) => {
      this.userList = res.list
    })
  }

  allShow (val: boolean) {
    if (val) {
      this.userList.map((user: {userId: string}) => {
        this.info.messageReceiverList.push({
          receiverId: user.userId
        })
      })
      console.log(this.info.messageReceiverList)
      this.checkboxGroup = []
    } else {
      this.info.messageReceiverList = []
    }
  }

  checkboxChange (valueList: string[]) {
    this.info.messageReceiverList = []
    valueList.map((value: string) => {
      this.info.messageReceiverList.push({
        receiverId: value
      })
    })
  }

  // 效验表单
  verification (status: string) {
    (this.$refs.form as ElForm).validate(valid => {
      if (valid) {
        this.onSubmit(status)
      }
    })
  }

  onSubmit (status: string) {
    this.submitShow = true
    const url = this.$route.params.id ? this.$apis.message.updateMessage : this.$apis.message.insertMessage
    this.$axios.post(url, {
      ...this.info,
      status: status
    }).then(() => {
      this.$message.success('保存成功')
      this.$router.back()
    }).finally(() => {
      this.submitShow = false
    })
  }
}
